import React, { useState, useEffect } from 'react'

const Cringe: React.FC = () => {
    const calculateTimeUntil = () => {
        // Calculate the time until October 4th, 2024, 8:15 pm GMT-6

        const targetDate = new Date('2024-10-04T20:15:00-06:00')
        const currentDate = new Date()

        let difference = targetDate.getTime() - currentDate.getTime()

        let days = Math.floor(difference / (1000 * 60 * 60 * 24))
        difference -= days * 1000 * 60 * 60 * 24

        let hours = Math.floor(difference / (1000 * 60 * 60))

        difference -= hours * 1000 * 60 * 60

        let minutes = Math.floor(difference / (1000 * 60))

        difference -= minutes * 1000 * 60

        let seconds = Math.floor(difference / 1000)

        difference -= seconds * 1000

        let milliseconds = difference

        return { days, hours, minutes, seconds, milliseconds }
    }

    const [timeUntil, setTimeUntil] = useState(calculateTimeUntil())

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeUntil(calculateTimeUntil())
        }, 1) // Update every millisecond

        return () => clearInterval(interval)
    }, [])

    return (
        <div>
            <div className="cringe-container" id="cringe">
                <h1>Time until we can hold hands</h1>
                <p>
                    Only<br></br>
                    {timeUntil.days} days<br></br>
                    {timeUntil.hours}hours,<br></br>
                    {timeUntil.minutes} minutes,<br></br>
                    {timeUntil.seconds} seconds, and<br></br>
                    {timeUntil.milliseconds} milliseconds left!
                </p>
            </div>
            <div className="flights">
                <div>
                    <a
                        href="https://de.flightaware.com/live/flight/AAL9016/history/20241004/0700Z/EDDB/EFHK"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            color: 'white',
                            textDecoration: 'underline',
                        }}
                    >
                        First flight from Berlin to Helsinki <br /> <br />
                    </a>
                </div>
                <div>
                    <a
                        style={{
                            color: 'white',
                            textDecoration: 'underline',
                        }}
                        href="https://www.flightaware.com/live/flight/AAL9019/history/20241004/0935Z/EFHK/KDFW"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Second flight to Dalles <br /> <br />
                    </a>
                </div>
                <div>
                    <a
                        style={{
                            color: 'white',
                            textDecoration: 'underline',
                        }}
                        href="https://www.flightaware.com/live/flight/AAL4908/history/20241005/0012Z/KDFW/KDRO"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Last one to Durango!!! <br /> <br />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Cringe
